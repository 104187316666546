@import '../../App';

.unavailable {
  &-container {
    --overflow: hidden;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 270px;

    &__image {
      width: 85px;
      height: 85px;
    }

    &__title {
      @extend .app-text-detail;
      margin-top: 40px;
      width: 220px;
    }

    &__button {
      @extend .app-button;
      margin-top: 40px;
      width: 100%;
    }
  }
}