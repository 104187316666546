@import '../../App';

.promo-intro {
  &-container {
    --background: #0c2a3e;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 63px var(--app-margin-extra-large) 45px;
  min-height: 100%;
  
  // lbaglie: we don't use CSS vars, because it must not be affected by them
  color: white;

  &__close {
    @extend .app-button--no-shadow;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__header {
    height: 105px;
  }

  &__subheader {
    margin-top: var(--app-margin-extra-large);
    text-align: center;

    &__chance {
      font-family: app-primary;
      font-size: 20px;
      text-transform: uppercase;
    }

    &__discount {
      @extend .app-text-title--medium;
      width: 100%;
      font-weight: bold;
      text-transform: uppercase;
    }

    &__end {
      @extend .app-text-body;
      margin-top: 3px;
      font-size: var(--app-font-size-very-small);
    }
  }

  &__message {
    @extend .app-text-body;
    margin-top: var(--app-margin-extra-large);
    text-align: center;
  }

  &__button {
    @extend .app-text-body;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 42px;
    width: 250px;
    height: var(--app-height-button);
    text-transform: uppercase ;
    font-weight: bold;
    font-size: var(--app-font-size-large-body);

    border-radius: 30px;
    background-image: linear-gradient(to bottom, #ffb805, #d87f00);
  }

  &__disclaimer {
    @extend .app-text-body;
    margin-top: var(--app-margin-large);
    text-align: center;
    width: 250px;
    font-size: var(--app-font-size-very-small);
  }
}