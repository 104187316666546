@import '../../App';

.special-alert {
  @extend .app-alert; 

  & .alert-button-group {
    background: var(--ion-color-secondary);
    justify-content: center;
    align-items: center;
    padding: 0;

    & .alert-button {
      padding: 0;
      margin: 0;
    }

    & .sc-ion-alert-md, & .sc-ion-alert-ios {
      @extend .app-button;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: var(--app-color-very-light);
    }
  }
}