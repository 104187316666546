.clickable {
  cursor: pointer;

  &--ripples {
    @extend .clickable;
    position: relative;
    overflow: hidden;
  }
}

.clickable-anchor {
  cursor: pointer;
}