@import '../../App';

.gold-congrats {
  @extend .app-text-body;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__image {
    width: 200px;
    height: 200px;
  }

  &__subtitle {
    @extend .app-text-title--medium;
    margin-top: var(--app-margin-large);
    color: var(--app-color-subscriber);
  }

  &__message {
    @extend .app-text-detail;
    margin-top: var(--app-margin-large);
    color: var(--app-color-text-on-background);
  }
}
