@import '../../../App';

$subheader-color: #FFFFFF;
$subheader-color-discount:#FFFFFF;
$promo-detail-check:  #04FFEB;

.promoCyberMonday-2024 {
  .promo-intro {
    background: imageUrl('cyberMonday/2024/bg.svg');
    background-repeat: no-repeat;
    background-size: cover;

    &__header {
      width: 72%;
    }

    &__subheader {
      &__chance {
        color: $subheader-color;
      }

      &__discount {
        background: $subheader-color-discount;
        color: black;
        border-radius: 15px;
        font-style: bold;
      }
    }
  }

  .promo-detail {
    background: imageUrl('cyberMonday/2024/bg.svg');
    background-repeat: no-repeat;
    background-size: cover;

    &__subheader {
      color: $subheader-color;
    }
    
    &__item__check {
      color: $promo-detail-check;
    }
  }

  .promo__price {
    color: $subheader-color;
  }
}
