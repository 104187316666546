@import '../../App';

.purchase {
  &-modal {
    & .modal-wrapper {
      width: 100%;
      height: 100%;
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 var(--app-margin-extra-large);

  &__title {
    @extend .app-text-title--medium;
    text-align: center;
  }

  &__message {
    @extend .app-text-body--small;
    margin-top: var(--app-margin-extra-large);
    text-align: center;
  }

  &__item {
    &-container {
      margin-top: var(--app-margin-extra-large);
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
    }

    $width-item: 132.5px;
    $width-item-highlight: 120px;
    $height-item-chip: 22px;

    width: $width-item;

    &:not(:first-child) {
      margin-left: var(--app-margin-breath);
    }

    &__highlight{
      @extend .app-text-body--small;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      margin-left: ($width-item - $width-item-highlight) / 2;
      padding: 0 10px;
      width: $width-item-highlight;
      height: $height-item-chip;
      z-index: 10;
      border-radius: $height-item-chip / 2;
      font-weight: bold;
      background: #3A7BA6;
    } 

    &__content {
      margin-top: $height-item-chip / 2 - 2;
      padding: 25px var(--app-margin-default) 0;
      width: $width-item;
      height: 150px;
      border: solid 2px var(--app-color-background-dark-er);
      text-align: center;

      &--selected {
        @extend .purchase__item__content;
        background-image: linear-gradient(to bottom, #5db4c8, #02608d);
        border: solid 2px var(--ion-color-primary-shade);
        color: var(--app-color-very-light);
      }

      &__price {
        font-family: 'app-secondary-bold';
        font-size: var(--app-font-size-large-body);
        font-weight: 900;
      }

      &__reference {
        @extend .app-text-body--small;
        margin-top: var(--app-margin-default);
      }

      &__discount {
        @extend .app-text-body--small;
        display: flex;
        align-items: center;
        justify-content: center;
        height: $height-item-chip;
        border-radius: $height-item-chip / 2;
        font-weight: bold;
        margin-top: var(--app-margin-default);
        background: var(--ion-color-primary);
      }
    }
  }

  &__button {
    @extend .app-button, .app-text-title--small;
    font-family: 'app-primary-bold';
    font-size: var(--app-font-size-small);
    margin-top: 45px;
    width: 260px;
  }

  &__restore {
    @extend .app-text-body--small;
    margin-top: 10px;
    text-decoration: underline;
  }

  &__disclaimer {
    @extend .app-text-body--small;
    margin-top: 10px;

    & a {
      text-decoration: underline;
    }
  }
}