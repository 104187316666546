@import '../../App';

.welcome-offer {
  & .promo-detail {
    background: #FFF;

    &__close {
      --color: var(--ion-color-primary);
    }

    &__header {
      height: 85px;
    }

    &__subheader, &__message, &__item-container, &__terms {
      color: #5b5b5b;

      & a {
        color: var(--ion-color-primary);
      }
    }

    &__item__check {
      color: #ffa100;
    }
  }
}