@import '../../App';

.cybermonday-2021 {
  & .promo-intro, & .promo-detail {
    &-container {
      --background: #2f516f;
    }

    // background: imageUrl('cybermonday/2021/bg.svg');
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: contain;
  }

  & .promo-intro {
    &__subheader {
      &__discount {
        background: white;
        color: #2f516f;
        border-radius: 15px;
        font-style: italic;
      }
    }

    &__button {
      background-image: linear-gradient(to bottom, #ff8fc1, #d50167);
    }
  }

  .promo-detail {
    &__item__check {
      color: #ff6bae;
    }
  }
}