@import '../../App';

.promo-detail {
  &-container {
    --background: #0c2a3e;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 55px;
  min-height: 100%;
  
  // lbaglie: we don't use CSS vars, because it must not be affected by them
  color: white;

  &__close {
    @extend .app-button--no-shadow;
    position: absolute;
    top: 0;
    left: 0;
    --color: #FFF;
    --background: transparent;
    --box-shadow: none;
    box-shadow: none;
  }

  &__header {
    height: 100%;
  }

  &__subheader {
    @extend .app-text-title;
    width: 320px;
    margin-top: var(--app-margin-extra-large);
    text-align: center;
    font-size: 22px;
  }

  &__message {
    @extend .app-text-body--small;
    margin-top: var(--app-margin-extra-large);
    text-align: center;
  }

  &__item {
    &-container {
      margin-top: var(--app-margin-default);
    }

    @extend .app-text-body--small;
    display: flex;
    margin-top: var(--app-margin-large);
    font-weight: bold;

    &__check {
      font-size: 18px;
      min-width: 18px;
    }

    &__text {
      margin-left: var(--app-margin-large);
      width: 202px;
    }
  }

  &__button {
    @extend .app-text-body--small;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 34px;
    width: 260px;
    height: var(--app-height-button);
    border-radius: 10px;
    background-image: linear-gradient(to bottom, #f8b406, #d98000);

    &__top {
      @extend .app-text-body;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  &__terms {
    @extend .app-text-body--small;
    margin-top: var(--app-margin-large);
    text-align: center;
    & a {
      color: white;
      text-decoration: underline;
    }
  }
}