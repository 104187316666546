@import '../../App';

.cybermonday-2022 {
  & .promo-intro, & .promo-detail {
    &-container {
      --background: #2f516f;
    }

    background: imageUrl('cyberMonday/2022/bg.svg');
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: cover;
  }

  & .promo-intro {
    &__subheader {
      &__discount {
        background: white;
        color: #2f516f;
        border-radius: 15px;
        font-style: italic;
      }
    }

    &__button {
      background-image: linear-gradient(to bottom, #93e489, #189481);
    }
  }

  .promo-detail {
    &__item__check {
      color: #a8fd90;
    }
  }
}